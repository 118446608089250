<template>
    <div >
        <img style="padding: 10px" src="../assets/kmlogo.4d8a1f39.png">
        <div>
            Kong Ming Chinese Takeaways
            <br>100 Victoria Street, Rangiora
            <br>
            <a href="tel:+64-3-313-5857" class="ph">03 313 5857</a>
            <hr>
            <h2>Hours</h2>
              <center>
            <table>
                <tr v-for="day in hours" v-bind:key="day.day">
                    <td style="padding-right: 8px">{{day.day}}</td>
                    <div v-for="time in day.time" v-bind:key="time.dinner">
                        <td>{{time.dinner}}</td>
                    </div>
                </tr>
            </table>
        </center>

        </div>
    </div>
</template>

<script>
import hours from './hours.json'
    export default {
        name: "home",

     data(){
            return{
                hours
            }
     }
    }
</script>

<style scoped>

</style>
