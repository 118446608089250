<template>

    <div>
        <center>
            <h1>Set Menus</h1>
            <hr>
            <div v-masonry transition-duration="0.001s" item-selector=".item" fit-width="true" origin-top="true"
                 horizontal-order="true">
                <div class="row">
                    <div v-masonry-tile class="item" v-for="item in setmenus" v-bind:key="item.Type">
                        <b-card>
                            <b-card-header><h2>{{item.Type}}</h2>
                                <i>{{item.Description}}</i>
                            </b-card-header>
                            <b-card-text>

                                <table align="center">
                                    <tr v-for="food in item.items" v-bind:key="food.items">
                                        <td>{{food}}</td>
                                    </tr>
                                </table>
                            </b-card-text>
                        </b-card>
                    </div>
                </div>

            </div>
            </center>
<!--      <div style="padding-top: 200px">-->
<!--        Prices subject to change without notice-->
<!--        <br>-->
<!--        Online menu - www.kongming.co.nz-->
<!--      </div>-->
    </div>

</template>

<script>
import setmenus from './setmenu.json'
    export default {
        name: "set-menu",

        data(){
            return{
                setmenus
            }
        }
    }
</script>

<style scoped>
    .card {
        border: none;
        background: none;
        width: 340px;
    }
</style>
