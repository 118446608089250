<template>
    <div>
        <div>
            <b-navbar toggleable="sm" type="light" variant="danger">
                <b-navbar-brand router-link class="logo" :to="{name: 'home'}">KONG MING</b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item router-link class="nav-link" :to="{name: 'home'}">Home</b-nav-item>
                        <b-nav-item router-link class="nav-link" :to="{name: 'menu'}">Menu</b-nav-item>
                        <b-nav-item router-link class="nav-link" :to="{name: 'setmenu'}">Set Menus</b-nav-item>
                        <b-nav-item router-link class="nav-link" :to="{name: 'location'}">Location</b-nav-item>

                        <!--<b-nav-item router-link class="nav-link" href="https://shop.kongming.co.nz" target="_blank">-->
                            <!--<b>Order Here</b></b-nav-item>-->

                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "header"
    }
</script>

<style scoped>
.navbar-brand, .navbar-brand:active, .navbar-brand:hover, .navbar-brand:visited {
    font-family: "Times New Roman";
    color: white;
    font-weight: bold;
    padding: 5px;
}

</style>
