<template>
    <div><h1>Location</h1>
    <p>
        100 Victoria Street, Rangiora
      <br>
      <a href="tel:+64-3-313-5857" class="ph">03 313 5857</a>
    </p>
        <iframe data-v-488aedf1=""
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDYWJqglMIQu9OXuX90TPZanTHV_jl7Uvg&amp;q=place_id:ChIJHUvabY2UMW0RdfuhTSrWhOw"
                style="width: 90%; height: 450px; border: 0px;"></iframe>
    </div>

</template>

<script>
    export default {
        name: "location"
    }
</script>

<style scoped>

</style>
