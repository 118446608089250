import '@babel/polyfill'
import Vue from 'vue';
import './plugins/bootstrap-vue';
import VueRouter from 'vue-router';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue';
import {VueMasonryPlugin} from 'vue-masonry'
import App from './App.vue';
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueMasonryPlugin)

import Home from './components/Home.vue';
import Menu from './components/Menu.vue';
import SetMenu from './components/SetMenu.vue';
import Location from './components/Location.vue';
import PrintMenu from './components/PrintMenu.vue';

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/menu",
        name: "menu",
        component: Menu
    },
    {
        path: "/set-menu",
        name: "setmenu",
        component: SetMenu
    },
    {
        path: "/location",
        name: "location",
        component: Location
    },
    {
        path: "/print",
        name: "printmenu",
        component: PrintMenu
    }

]

const router = new VueRouter({
    routes: routes,
    mode: 'history'
})

new Vue({
    el: '#app',
    router: router,
    render: h => h(App)
}).$mount('#app')
