<template>
<!--    <div style="background-color: #d3d3d3; font-size: larger; font-weight: normal;padding: 4px">-->
<!--      Closed 25 and 26 Dec 2023-->
<!--    </div>-->
  <div>
  </div>
</template>

<script>
    export default {
        name: "message"
    }
</script>
