<template>
    <div><h1>Menu</h1>
        <hr>
        <div v-masonry transition-duration="0.001s" item-selector=".item" fit-width="true" origin-left="true">
            <div class="row">
                <div v-masonry-tile class="item" v-for="item in menu" v-bind:key="item.Category">
                    <b-card>
                        <b-card-header>
                            <h2>{{ item.Category }}</h2>
                            <i v-if="item.Description">{{item.Description}}</i>
                        </b-card-header>
                        <b-card-text>
                            <table class="menutable" align="center">
                                <tr v-for="food in item.items" v-bind:key="food.items">
                                    <td>{{food.item}}
<!--                                      <b v-if="food.gf" class="gf">*</b>-->
                                    </td>
                                    <td style="padding-left: 3px">{{food.price}}</td>
                                </tr>
                            </table>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
        </div>


<!--        <b class="gf">*</b> Meals can be cooked without gluten ingredients on request-->


    </div>


</template>

<script>
import menu from './menu.json'
    export default {
        name: "menu",

        data(){
            return{
                menu
            }
        }

    }
</script>

<style scoped>


.gf{
        font-size: 12px;
        font-weight: bold;
    }
.menutable{
    border: 0px #000;
    position: center;
    width: 315px;
}
.card {
    border: none;
    background: none;
    width: 350px;
}

</style>
