<template>
  <div>
    <div style="display: flex;
         align-items: center;
         justify-content: space-evenly">
      <div style="padding-top: 20px">Locally owned and operated <br>
        since 1986</div>

      <div><h1><span style="font-family: 'Times New Roman'; font-weight: bold">KONG MING</span>
      </h1>
        100 Victoria Street <br>Rangiora<br>313 5857
      </div>
      <div>
        <br>HOURS <br>
        Tue - Sun <br>
        4:30pm-8pm
      </div>
    </div>
    <div v-masonry transition-duration="0.001s" item-selector=".item" fit-width="true" origin-left="true">
      <div class="row">
        <div v-masonry-tile class="item" v-for="item in menu" v-bind:key="item.Category">
          <b-card>
            <b-card-header style="padding: 5px">
              <h2>{{ item.Category }}</h2>
              <i v-if="item.Description">{{item.Description}}</i>
            </b-card-header>
            <b-card-text>
              <table class="menutable" align="center">
                <tr v-for="food in item.items" v-bind:key="food.items">
                  <td>{{food.item}}
<!--                    <b v-if="food.gf" class="gf">*</b>-->
                  </td>
                  <td style="padding-left: 3px">{{food.price}}</td>
                </tr>
              </table>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </div>



  </div>


</template>

<script>
import menu from './menu.json'
export default {
  name: "menu",

  data(){
    return{
      menu
    }
  }

}
</script>

<style scoped>


.gf{
  font-size: 8px;
  font-weight: bold;
}
.menutable{
  border: 0px #000;
  position: center;
  width: 315px;
}
.card {
  border: none;
  background: none;
  width: 350px;
}

</style>
