<template>
  <div id="app">
    <top-nav></top-nav>
    <center>
      <message></message>
    <div id="body">
      <router-view></router-view>
    </div>
    </center>
  </div>
</template>

<script>
import TopNav from './components/Header.vue';
import Message from './components/Message.vue';


export default {
  name: 'App',
  components: {
      TopNav,
      Message
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#body{
    position: center;
    width: 95%;
    max-width: 1920px;
    padding: 10px;
  }
.card-header {
  background: none;
  border: none;
}

a, .ph:link, .ph:visited, .ph:active, .ph:hover{
  color: #2c3e50;
  text-decoration: none;
}

</style>
